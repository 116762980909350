<script>
import axios from 'axios'
import * as stringHelper from '../../helpers/string'

/**
 * Dashboard Component
 */
export default {
  props: {
    parent: Object,
    searchByParent: Boolean
  },
  components: {},
  data() {
    return {
      partner: null,
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'seamless',
      responseWarnInterval: 300,
      username: 'user1',
      list: []
    };
  },
  methods: {
    async execute(url, data) {
      const params = {url, data}
      try {
        const result = await axios.post(this.apiUrl, params, {
          headers: {
            accept: 'application/json',
            contentType: 'application/x-www-form-urlencoded',
            'k-secret': this.partner.apiKey,
            'k-username': this.partner.username
          }
        })
        return result.data
      } catch (e) {
        this.$log.error(e)
      }
    },
    async run() {
      const transactionKey = new Date().getTime().toString()

      try {
        for (const key in this.list) {
          const record = this.list[key]
          record.status = 'ready'
          record.errMsg = ''
          record.request = ''
          record.response = ''
          record.interval = 0
        }

        // 이전 배팅 코드
        let preBetTrxKey
        // 이전 결과 코드
        let preResultTrxKey
        // 이전 취소 코드
        let preCancelTrxKey
        // 잔액
        let balance = 0

        for (const key in this.list) {
          const record = this.list[key]
          const url = record.url
          let data = this.getData(record.category)

          // 사용할 코드
          const trxKey = stringHelper.md5(transactionKey + record.key)

          // 데이터 조합
          if (record.category === 'bet') {
            data.params.username = this.partner.prefix + '_' + this.username
            data.params.siteUsername = this.username
            data.params.transactionKey = trxKey
            data.params.key = transactionKey + '-key'
            if (record.type === 'normal') {
              preBetTrxKey = data.params.transactionKey
            }
          } else if (record.category === 'result') {
            data.params.username = this.partner.prefix + '_' + this.username
            data.params.siteUsername = this.username
            data.params.parentTransactionKey = preBetTrxKey
            data.params.transactionKey = trxKey + '-win'
            data.params.key = transactionKey + '-wkey'
            if (record.type === 'normal') {
              preResultTrxKey = data.params.transactionKey
            }
          } else if (record.category === 'cancel') {
            data.params.username = this.partner.prefix + '_' + this.username
            data.params.siteUsername = this.username
            data.params.parentTransactionKey = preBetTrxKey
            data.params.transactionKey = trxKey + '-cancel'
            data.params.key = transactionKey + '-ckey'
            if (record.type === 'normal') {
              preCancelTrxKey = data.params.transactionKey
            }
          } else if (record.category === 'balance') {
            data.params = {
              siteUsername: this.username,
              username: this.partner.prefix + '_' + this.username
            }
          } else {
            data.params.siteUsername = this.username
          }

          data.apiKey = this.partner.apiKey

          // type에 따라 data를 변경
          if (record.type === 'invalid-user') {
            data.params.siteUsername = 'invalid-user'
            if (data.params.username) {
              data.params.username = 'invalid-user'
            }
          } else if (record.type === 'invalid-apikey') {
            data.apiKey = 'invalid-key'
          } else if (record.type === 'duplicated') {
            // 이전의 데이터의 거래코드 입력
            if (record.category === 'bet') {
              data.params.transactionKey = preBetTrxKey
            } else if (record.category === 'result') {
              data.params.transactionKey = preResultTrxKey
            } else if (record.category === 'cancel') {
              data.params.transactionKey = preCancelTrxKey
            }
          } else if (record.type === 'invalid-amount') {
            data.params.amount = -1
          } else if (record.type === 'over-bet-amount') {
            data.params.amount = 999999999
          }

          record.request = JSON.stringify(data, null, ' ')

          const result = await this.execute(url, data)
          record.interval = result.interval
          delete result.interval
          if (typeof result !== 'object' || typeof result.code !== 'number') {
            record.status = 'fail'
            record.errMsg = '데이터 형식을 확인하세요.'
            record.response = JSON.stringify(result, null, ' ')
            break
          }

          record.response = JSON.stringify(result, null, ' ')

          if (record.category === 'authenticate' && record.type === 'normal') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = result.msg
              break
            }
            balance = result.data.balance
          }

          if (record.category === 'balance' && record.type === 'normal') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = result.msg
              break
            }
            balance = result.data.balance
          }

          if (record.category === 'bet' && record.type === 'normal') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = result.msg
              break
            }

            const preBalance = balance
            balance = (balance - data.params.amount).floatVal(2)
            if (balance !== result.data.balance) {
              record.status = 'fail'
              record.errMsg = `잔액은 ${preBalance} - ${data.params.amount} = ${balance}이어야 합니다.`
              break
            }
          }

          if (record.category === 'result' && record.type === 'normal') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = result.msg
              break
            }

            const preBalance = balance
            balance = (balance + data.params.amount).floatVal(2)
            if (balance !== result.data.balance) {
              record.status = 'fail'
              record.errMsg = `잔액은 ${preBalance} + ${data.params.amount} = ${balance}이어야 합니다.`
              break
            }
          }

          if (record.category === 'cancel' && record.type === 'normal') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = result.msg
              break
            }

            const preBalance = balance
            balance = (balance + data.params.amount).floatVal(2)
            if (balance !== result.data.balance) {
              record.status = 'fail'
              record.errMsg = `잔액은 ${preBalance} + ${data.params.amount} = ${balance}이어야 합니다.`
              break
            }
          }

          if (record.type === 'invalid-user') {
            if (result.code === 0 || result.code === -999) {
              record.status = 'fail'
              record.errMsg = 'username이 사이트에 존재하는 회원인지 확인하세요.'
              break
            }
          }

          if (record.type === 'invalid-apikey') {
            if (result.code === 0 || result.code === -999) {
              record.status = 'fail'
              record.errMsg = 'apiKey가 API으로부터 부여받은 Api Key와 일치하는지 확인하세요.'
              break
            }
          }

          if (record.type === 'duplicated') {
            if (result.code !== 0) {
              record.status = 'fail'
              record.errMsg = '중복된 거래 내역이 전송되는 경우 입력은 하지 말고 정상적인 응답을 하셔야 합니다.'
              break
            }
          }

          if (record.category !== 'cancel' && record.type === 'invalid-amount') {
            if (result.code === 0 || result.code === -999) {
              record.status = 'fail'
              record.errMsg = 'params.amount가 0이하 또는 문자인지 확인하세요.'
              break
            }
          }

          if (record.type === 'over-bet-amount') {
            if (result.code === 0 || result.code === -999) {
              record.status = 'fail'
              record.errMsg = '배팅금액이 보유금보다 큰지 확인하세요.'
              break
            }
          }

          record.status = 'success'
        }
      } catch (e) {
        alert('테스트중 오류가 발생하였습니다.')
        this.$log.error(e)
      }
    },
    getData(category) {
      const data = {
        authenticate: {
          "apiKey": "9ab37139a643d71a5a885c75709ffa17",
          "params": {siteUsername: ''},
          "requestAt": new Date()
        },
        balance: {
          "apiKey": "9ab37139a643d71a5a885c75709ffa17",
          "params": {username: '', siteUsername: ''},
          "requestAt": new Date()
        },
        bet: {
          "apiKey": "9ab37139a643d71a5a885c75709ffa17",
          params: {
            "transactionKey": "629b24bb93f3ea9900f0e61e873952a8",
            "username": this.partner.prefix + "_" + this.username,
            "siteUsername": this.username,
            "vendorKey": "pragmatic_slot",
            "vendorName": "프라그마틱 슬롯",
            "gameCategoryKey": "slot",
            "gameKey": "vs20doghouse",
            "gameName": "The Dog House",
            "gameTypeKey": "slot",
            "type": "bet",
            "key": "631fd2c6f35383513fab5654",
            "parentTransactionKey": null,
            "isBonus": false,
            "isJackpot": false,
            "isPromo": false,
            "isFreeGame": false,
            "groupKey": "15",
            "amount": 1,
            "createdAt": "2022-09-13T00:45:58.496Z",
            "requestedAt": "2022-09-13T00:45:58.696Z"
          },
          "requestAt": new Date()
        },
        result: {
          "apiKey": "9ab37139a643d71a5a885c75709ffa17",
          params: {
            "transactionKey": "629b24bb93f3ea9900f0e61e873952a8",
            "username": "top1_user1",
            "siteUsername": "user1",
            "vendorKey": "pragmatic_slot",
            "vendorName": "프라그마틱 슬롯",
            "gameCategoryKey": "slot",
            "gameKey": "vs20doghouse",
            "gameName": "The Dog House",
            "gameTypeKey": "slot",
            "type": "win",
            "key": "631fd2c6f35383513fab5654",
            "parentTransactionKey": null,
            "isBonus": false,
            "isJackpot": false,
            "isPromo": false,
            "isFreeGame": false,
            "groupKey": "15",
            "amount": 1.02,
            "createdAt": "2022-09-13T00:45:58.496Z",
            "requestedAt": "2022-09-13T00:45:58.696Z"
          },
          "requestAt": new Date()
        },
        cancel: {
          "apiKey": "9ab37139a643d71a5a885c75709ffa17",
          params: {
            "transactionKey": "629b24bb93f3ea9900f0e61e873952a8",
            "username": "top1_user1",
            "siteUsername": "user1",
            "vendorKey": "pragmatic_slot",
            "vendorName": "프라그마틱 슬롯",
            "gameCategoryKey": "slot",
            "gameKey": "vs20doghouse",
            "gameName": "The Dog House",
            "gameTypeKey": "slot",
            "type": "cancel",
            "key": "631fd2c6f35383513fab5654",
            "parentTransactionKey": null,
            "isBonus": false,
            "isJackpot": false,
            "isPromo": false,
            "isFreeGame": false,
            "groupKey": "15",
            "amount": 1,
            "createdAt": "2022-09-13T00:45:58.496Z",
            "requestedAt": "2022-09-13T00:45:58.696Z"
          },
          "requestAt": new Date()
        }
      }

      return data[category]
    }
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }
     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'seamless'

    this.partner = this.parent ? this.parent : this.$store.getters['auth/user']
    this.list = [
      {
        key: 'kgn-' + 1,
        category: 'authenticate',
        type: 'normal',
        title: '회원인증',
        url: this.partner.seamlessCallbackUrl.authenticate,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        sample: '',
        desc: '----------------------------------------------------------------------------\n' +
            '[성공시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n'+
            '  code: 0,\n'+
            '  data: {\n'+
            '    username: "user1", // API으로 전송한 username\n'+
            '    siteUsername: "user1", // API에 저장할 사이트 username\n'+
            '    groupKey: "shop1",// 회원 구분 코드(사이트에서 임의로 지정)\n'+
            '  }\n'+
            '}\n' +
            '----------------------------------------------------------------------------\n' +
            '[실패시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'사이트에서 필요로 하는 디버그 메세지\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 10,
        category: 'authenticate',
        type: 'invalid-user',
        title: '회원인증(회윈 미존재)',
        url: this.partner.seamlessCallbackUrl.authenticate,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 미존재 회원 정보를 전송했기에 실패 메세지를 전송\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'존재하지 않는 회원입니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 20,
        category: 'authenticate',
        type: 'invalid-apikey',
        title: '회원인증(api key 미일치)',
        url: this.partner.seamlessCallbackUrl.authenticate,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - API Key가 일치하지 않으므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'API키가 일치하지 않습니다.\'\n' +
            '}'
      },

      {
        key: 'kgn-' + 1000,
        category: 'balance',
        type: 'normal',
        title: '잔액확인',
        url: this.partner.seamlessCallbackUrl.balance,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시]\n' +
            '----------------------------------------------------------------------------\n' +
            '  {\n' +
            '    code:0,\n' +
            '    data: {\n' +
            '      balance:10000,\n' +
            '      currency:"KRW"\n' +
            '    }\n' +
            '  }\n' +
            '----------------------------------------------------------------------------\n' +
            '[실패시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'사이트에서 필요로 하는 디버그 메세지\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 1010,
        category: 'balance',
        type: 'invalid-user',
        title: '잔액확인(회원 미존재)',
        url: this.partner.seamlessCallbackUrl.balance,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 미존재 회원 정보를 전송했기에 실패 메세지를 전송\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'존재하지 않는 회원입니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 1020,
        category: 'balance',
        type: 'invalid-apikey',
        title: '잔액확인(api key 미일치)',
        url: this.partner.seamlessCallbackUrl.balance,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - API Key가 일치하지 않으므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'API키가 일치하지 않습니다.\'\n' +
            '}'
      },

      {
        key: 'kgn-' + 2000,
        category: 'bet',
        type: 'normal',
        title: '배팅',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code:0,\n' +
            '  data: {\n' +
            '    balance:9000,\n' +
            '    beforeBalance:10000,\n' +
            '    currency:"KRW"\n' +
            '  }\n' +
            '}\n' +
            '----------------------------------------------------------------------------\n' +
            '[실패시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'사이트에서 필요로 하는 디버그 메세지\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 2010,
        category: 'bet',
        type: 'invalid-user',
        title: '배팅(회원 미존재)',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 미존재 회원 정보를 전송했기에 실패 메세지를 전송\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'존재하지 않는 회원입니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 2020,
        category: 'bet',
        type: 'invalid-apikey',
        title: '배팅(api key 미일치)',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - API Key가 일치하지 않으므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'API키가 일치하지 않습니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 2030,
        category: 'bet',
        type: 'duplicated',
        title: '배팅(중복 자료)',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 이미 입력된 데이터이므로 입력은 하지 말고 성공 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code:0,\n' +
            '  data: {\n' +
            '    balance:9000,\n' +
            '    beforeBalance:10000,\n' +
            '    currency:"KRW"\n' +
            '  }\n' +
            '}'
      },
      {
        key: 'kgn-' + 2040,
        category: 'bet',
        type: 'invalid-amount',
        title: '배팅(금액 오류)',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 배팅금액이 0보다 작거나 Number가 아니므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'금액이 올바르지 않습니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 2050,
        category: 'bet',
        type: 'over-bet-amount',
        title: '배팅(보유금액 초과)',
        url: this.partner.seamlessCallbackUrl.bet,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 배팅금액이 보유금액보다 크므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'금액을 확인하여 주세요.\'\n' +
            '}'
      },

      {
        key: 'kgn-' + 3000,
        category: 'result',
        type: 'normal',
        title: '배팅결과',
        url: this.partner.seamlessCallbackUrl.result,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시]\n' +
            '----------------------------------------------------------------------------\n' +
            '  {\n' +
            '    code:0,\n' +
            '    data: {\n' +
            '      balance:10000,\n' +
            '      beforeBalance:9000,\n' +
            '      currency:"KRW"\n' +
            '    }\n' +
            '  }\n' +
            '----------------------------------------------------------------------------\n' +
            '[실패시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'사이트에서 필요로 하는 디버그 메세지\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 3010,
        category: 'result',
        type: 'invalid-user',
        title: '배팅결과(회원 미존재)',
        url: this.partner.seamlessCallbackUrl.result,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 미존재 회원 정보를 전송했기에 실패 메세지를 전송\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'존재하지 않는 회원입니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 3020,
        category: 'result',
        type: 'invalid-apikey',
        title: '배팅결과(api key 미일치)',
        url: this.partner.seamlessCallbackUrl.result,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - API Key가 일치하지 않으므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'API키가 일치하지 않습니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 3030,
        category: 'result',
        type: 'duplicated',
        title: '배팅결과(중복 자료)',
        url: this.partner.seamlessCallbackUrl.result,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 이미 입력된 데이터이므로 입력은 하지 말고 성공 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code:0,\n' +
            '  data: {\n' +
            '    balance:9000,\n' +
            '    beforeBalance:10000,\n' +
            '    currency:"KRW"\n' +
            '  }\n' +
            '}'
      },
      {
        key: 'kgn-' + 3040,
        category: 'result',
        type: 'invalid-amount',
        title: '배팅결과(금액 오류)',
        url: this.partner.seamlessCallbackUrl.result,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 배팅금액이 0보다 작거나 Number가 아니므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'금액이 올바르지 않습니다.\'\n' +
            '}'
      },

      {
        key: 'kgn-' + 4000,
        category: 'cancel',
        type: 'normal',
        title: '배팅취소',
        url: this.partner.seamlessCallbackUrl.cancel,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시]\n' +
            '----------------------------------------------------------------------------\n' +
            '  {\n' +
            '    code:0,\n' +
            '    data: {\n' +
            '      balance:10000,\n' +
            '      beforeBalance:9000,\n' +
            '      currency:"KRW"\n' +
            '    }\n' +
            '  }\n' +
            '----------------------------------------------------------------------------\n' +
            '[실패시]\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'사이트에서 필요로 하는 디버그 메세지\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 4010,
        category: 'cancel',
        type: 'invalid-user',
        title: '배팅취소(회원 미존재)',
        url: this.partner.seamlessCallbackUrl.cancel,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 미존재 회원 정보를 전송했기에 실패 메세지를 전송\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'존재하지 않는 회원입니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 4020,
        category: 'cancel',
        type: 'invalid-apikey',
        title: '배팅취소(api key 미일치)',
        url: this.partner.seamlessCallbackUrl.cancel,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - API Key가 일치하지 않으므로 실패 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code: -1,\n' +
            '  msg: \'API키가 일치하지 않습니다.\'\n' +
            '}'
      },
      {
        key: 'kgn-' + 4030,
        category: 'cancel',
        type: 'duplicated',
        title: '배팅취소(중복 자료)',
        url: this.partner.seamlessCallbackUrl.cancel,
        errMsg: '',
        status: 'ready',
        request: '',
        response: '',
        interval: 0,
        desc:'----------------------------------------------------------------------------\n' +
            '[성공시] - 이미 입력된 데이터이므로 입력은 하지 말고 성공 메세지를 전송.\n' +
            '----------------------------------------------------------------------------\n' +
            '{\n' +
            '  code:0,\n' +
            '  data: {\n' +
            '    balance:9000,\n' +
            '    beforeBalance:10000,\n' +
            '    currency:"KRW"\n' +
            '  }\n' +
            '}'
      }
    ]
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="card-title-desc">{{ $t('text.api_seamless_test_desc') }}</div>
          <hr/>
          <div class="row">
            <div class="col-12">
              <b-card header-class="bg-transparent border-primary" class="border border-primary">
                <template v-slot:header>
                  <h5 class="my-0 text-primary">
                    <i class="mdi mdi-bullseye-arrow me-3"></i>심리스 전체 테스트
                  </h5>
                </template>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-primary font-size-11" style="width: 80px;">API KEY</span>
                  {{ partner.apiKey }}</p>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-success font-size-11" style="width: 80px;">인증</span>
                  {{ partner.seamlessCallbackUrl.authenticate }}</p>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-info font-size-11" style="width: 80px;">잔액</span>
                  {{ partner.seamlessCallbackUrl.balance }}</p>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-warning font-size-11" style="width: 80px;">배팅</span>
                  {{ partner.seamlessCallbackUrl.bet }}</p>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-secondary font-size-11" style="width: 80px;">결과</span>
                  {{ partner.seamlessCallbackUrl.result }}</p>
                <p class="card-text mb-0" v-if="partner"><span
                    class="badge badge-soft-pill badge-soft-danger font-size-11" style="width: 80px;">취소</span>
                  {{ partner.seamlessCallbackUrl.cancel }}</p>
                <hr/>
                <p class="card-text mb-0">
                  심리스 서비스를 적용하기 위하여 최종 테스트를 진행합니다. <br/>
                  전체 테스트를 통과하셔야 Production 계정이 발급되고 서비스를 오픈하실 수 있습니다.
                </p>
                <p class="text-danger">* 반환할 오류코드 : -999(사이트 내의 예외 오류), -1(일반적인 오류)</p>
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control text-center" style="font-size: 20px;"
                             :placeholder="'사이트에 등록되어 있는 회원아이디'" v-model="username"/>
                      <label class="form-label">사이트에 등록되어 있는 회원아이디</label>
                    </div>
                  </div>
                  <div class="col-4"></div>
                </div>
                <p class="text-center">
                  <button type="button" class="btn btn-primary btn-lg" @click="run">테스트 시작</button>
                </p>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div role="tablist">

                <b-card no-body class="mb-2" v-for="(record, index) in list" v-bind:key="record.code">
                  <b-card-header header-tag="header" role="tab" style="cursor: pointer;" class="rounded rounded-3"
                                 :class="{'bg-secondary': record.status === 'ready', 'bg-soft' : record.status === 'ready', 'bg-success': record.status === 'success', 'bg-danger': record.status === 'fail', 'bg-primary': record.status === 'request'}"
                                 v-b-toggle="record.key">
                    <h6 class="m-0 text-dark d-flex justify-content-between"
                        :class="{'text-white': record.status !== 'ready'}">
                      <span class="">#{{ (index + 1).formatThousands() }} - {{ record.title }}</span>
                      <span class="badge"
                            :class="{'bg-primary': record.interval <= responseWarnInterval, 'bg-danger': record.interval > responseWarnInterval}"
                            v-if="record.interval > 0">{{ (record.interval / 1000).floatVal(3) }}초</span>
                    </h6>
                  </b-card-header>
                  <b-collapse :id="record.key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-4">
                          <p class="bg-soft bg-primary p-2 rounded rounded-2 text-center mb-0 mt-0">전송 데이터</p>
                          <pre class="rounded" v-highlightjs>
                              <code class="language-javascript ps-4 pe-4 rounded rounded-2 mb-0 pb-0"
                                    style="max-height: 500px; overflow-y: auto">
{{ record.request }}
                              </code>
                            </pre>
                        </div>
                        <div class="col-md-4">
                          <p class="bg-soft bg-success p-2 rounded rounded-2 text-center mb-0 mt-0">수신 데이터 예시</p>
                          <pre class="rounded" v-highlightjs>
                              <code class="language-javascript ps-4 pe-4 rounded rounded-2 mb-0 pb-0"
                                    style="max-height: 500px; overflow-y: auto" v-if="record.desc">
{{ record.desc }}
                              </code>
                            </pre>
                        </div>
                        <div class="col-md-4">
                          <p class="bg-soft bg-secondary p-2 rounded rounded-2 text-center mb-0 mt-0">수신 데이터</p>
                          <pre class="rounded" v-highlightjs>
                              <code class="language-javascript ps-4 pe-4 rounded rounded-2 mb-0 pb-0"
                                    style="max-height: 500px; overflow-y: auto">
{{ record.response }}
                              </code>
                            </pre>
                        </div>
                      </div>
                      <div class="alert alert-danger" v-if="record.status === 'fail'">{{ record.errMsg }}</div>
                    </b-card-body>
                  </b-collapse>
                </b-card>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
